:global {
  :local(.ant-advanced-search-form) {
    .ant-form-item {
      display: flex;
    }

    .row_item {
      display: flex;
      align-items: center;

      &:nth-child(n+2) {
        margin-top: 24px;
      }

      &:last-child {
        margin-top: 16px;
      }

      .item_title {
        width: 110px;
        padding-right: 40px;
        text-align: right;
      }

      .select_root_wrapper {
        width: 300px;
        position: relative;

        .role_select_wrapper {
          width: 100%;
          min-height: 34px;
          padding: 5px 5px 2px;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          display: flex;
          flex-wrap: wrap;

          .role_item {
            height: 22px;
            background: rgba(230, 247, 255, 1);
            border-radius: 2px;
            border: 1px solid rgba(145, 213, 255, 1);
            padding: 1px 8px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: rgba(24, 144, 255, 1);
            margin-right: 8px;
            margin-bottom: 3px;

            >span {
              margin-left: 4px;
              cursor: pointer;
            }
          }
        }

        .role_select_values_wrapper {
          position: absolute;
          z-index: 1;
          left: 0;
          right: 0;
          top: 0;
          background: rgba(255, 255, 255, 1);
          // background: rgba(20, 20, 20, 1);
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
          border-radius: 4px;

          .select_values_content_wrapper {
            display: flex;
            flex-wrap: wrap;
            padding: 16px 16px 21px;

            .role_value_item {
              cursor: pointer;
              height: 22px;
              // background: rgba(0, 0, 0, 0.04);
              background: rgba(245, 245, 245, 1);
              border-radius: 2px;
              border: 1px solid rgba(0, 0, 0, 0.15);
              padding: 1px 8px;
              font-size: 12px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.65);
              display: flex;
              align-items: center;
              margin-right: 8px;
              margin-bottom: 3px;
            }

            .role_value_item_selected {
              background: rgba(230, 247, 255, 1);
              border: 1px solid rgba(145, 213, 255, 1);
              color: rgba(24, 144, 255, 1);
            }
          }
        }
      }
    }
  }

  :local(.PersonMng) {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    >.cus_spin_wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.04);
    }

    >.content_wrapper {
      padding: 24px;
      height: 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .headerOpt {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        // box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
        .header-opt-build {
          background-color: #357CF7;
        }

        .add_btn {
          background-color: #357CF7;
        }
      }

      >.table_wrapper {
        height: 0;
        flex-grow: 1;
        overflow-y: auto;
        position: relative;

        >.wrapperRec {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        >.content {
          position: relative;
        }

        .grey_table_row {
          >td {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }

    }

  }
}