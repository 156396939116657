:global {
    :local(.wrapper) {
        overflow-y: auto;
        .down_content {
            padding: 20px;
            border-color: unset;
            background-color: #FFF;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;
            flex-grow: 1;

            .tool_bar {
                width: 100%;
                height: 40px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .main_content {
                width: 100%;
                // height: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: flex-start;
                .item_card:nth-child(2n+1){
                    margin-left: 0px;
                }
                .item_card{
                    width: 500px;
                    height: 230px;
                    display: flex;
                    padding: 15px;
                    flex-direction: row;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
                    border-radius: 10px;
                    margin-left: 25px;
                    margin-top: 20px;
                    flex-wrap: no-wrap;
                    .left_img {
                        border-radius: 10px;
                        object-fit: cover;
                        height: 200px;
                        width: 150px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    .right_info{
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        padding-left: 15px;
                        padding-right: 0px;
                        .info_line{
                            flex-grow: 0;
                            display: flex;
                            flex-direction: row;
                            // min-height: 26px;
                            >.name {
                                color: #000;
                                font-weight: bold;
                                font-size: 16px;
                                &:hover {
                                    text-decoration: underline;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}