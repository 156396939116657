:global {
    :local(.wrapper) {
        height: 100%;

        >.tool_wrapper {
            padding: 12px 0;
            display: flex;
            align-items: center;
        }

        .btn-line {
            width: 150px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            margin-top: 40px;
        }
    }
}