:global {
    :local(.wrapper) {
        height: 100%;
        .btn-line{
            width: 180px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: 265px;
            margin-top: 40px;
        }
        .ant-table-thead > tr > th {
            font-weight: bold;
        }
    }
}