:global {
  :local(.wrapper) {
    height: 65px;
    width: 373px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 100);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;

    >.top {
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      // margin-bottom: -3px;
    }

    >.bottom {
      display: flex;
      align-items: flex-end;
      // margin-top: -3px;

      >.price {
        font-size: 14px;
        line-height: 17px;
      }

      >.total,
      >.rsvd_title,
      >.remain_title {
        font-size: 12px;
        line-height: 14px;
        margin-left: auto;
      }

      >.rsvd_value,
      >.remain_value {
        font-size: 28px;
        line-height: 28px;
        height: 28px;
      }

    }
  }
}