:global {
    :local(.wrapper) {
        overflow-y: auto;
        height: 100%;

        .CustomerDetails {
            box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;
            background-color: #FFF;
            flex-grow: 1;
            padding-left: 18px;
            padding-right: 18px;
        }
    }
}