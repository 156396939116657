:global {
  :local(.wrapper) {
    height: 100%;
    background-color: rgba(255, 255, 255, 100);
    display: flex;
    flex-direction: column;

    >.content {
      height: 0;
      flex-grow: 1;
      overflow-y: auto;
      margin-top: 20px;
      padding-bottom: 24px;

      >.block {
        // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-width: 0 1px 1px 1px;
        margin: 0 20px;

        >.top {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          position: sticky;
          z-index: 1;
          top: 0;
          background-color: rgba(255, 255, 255, 100);

          >.time_bar {
            padding: 19px 0;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(187, 187, 187, 0.7);
          }
        }
      }
    }
  }

  :local(.content_wrapper) {
    min-height: 65px;
    padding-left: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;

    >.item {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
}