:global {
    :local(.wrapper) {
        height: 100%;
        // padding: 24px 56px 0 72px;

        .ant-modal-body{
            padding-top: 20px;
            padding-left: 40px;
            padding-right: 40px;
        }

        .info_line{
            width: 100%;
            line-height: 50px;
            height: 50px;
            display: flex;
            flex-direction: row;
            font-size: 15px;
            .label{
                width: 100px;
                flex-shrink: 0;
                flex-grow: 0;
            }
            >.content{
                display: flex;
                position: relative;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }
    }
}