:global {
    :local(.wrapper) {
        background-color: #FFF;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;

        .btn-line{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            height: 90px;
            flex-shrink: 0;
        }
    }
}