:global {
    :local(.wrapper) {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;

        &>*{
            flex-shrink: 0;
        }
        & *{
            box-sizing: border-box;
        }

        .topLargeLogo{
            width: 100%;
            margin-top: 50px;
        }
        .waveLine{
            width: 100%;
        }

        .successText1{
            margin-top: 8px;
            font-size: 25px;
            font-weight: 800;
            color: #ec75a1;
        }
        .successText2{
            margin-top: 1px;
            font-size: 29px;
            font-weight: 800;
            color: #ec75a1;
        }

        .preVerifyTime{
            text-align: center;
            font-size: 14px;
            margin-top: 4px;
            color: #FFF;
        }

        .shortDivider{
            width: 48px;
            height: 2px;
            background-color: rgba(255, 255, 255, 0.7);
            margin-top: 20px;
        }
        .infoBlock{
            font-size: 18px;
            color: rgba(255, 255, 255, 0.7);
            margin-top: 16px;
            letter-spacing: 0.5px;
            line-height: 29px;
            text-align: center;
        }

        .downBlkWrapper{
            flex-grow: 1;
            display: flex;
            padding-bottom: 10px;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .failureText{
                color: #FFF;
                font-size: 22px;
                width: 100%;
                text-align: center;
                letter-spacing: 2px;
                line-height: 28px;
                margin-top: 13px;
                font-weight: 600;
            }
            .reserveBtn{
                width: 240px;
                height: 50px;
                background-color: rgba(255,255,255,0.7);
                border-radius: 3px;
                margin-top: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}