:global {
    :local(.wrapper) {
        >.item {
            >.title_row {
                display: flex;
                align-items: center;

                >.title {
                  margin-right: 20px;
                  // font-size: 20px;
                  // line-height: 29px;
                  // font-weight: bold;
                }
            }

            >.content_row {
                margin-top: 30px;
                display: flex;
                align-items: center;

                >.title {
                  color: rgba(39, 39, 39, 100);
                  // font-size: 16px;
                  // line-height: 24px;
                  // font-weight: bold;
                  margin-right: 4px;

                  &.title_2 {
                    margin-left: 30px;
                  }
                }

                >.remain_value{
                  color: rgba(0, 0, 0, 1);
                  // font-size: 14px;
                  // line-height: 20px;
                  // text-decoration: underline;
                  margin-left: 20px;
                  width: 70px;
                }
                >.rsvd_value {
                  color: rgba(0, 0, 0, 1);
                  // font-size: 14px;
                  // line-height: 20px;
                  // text-decoration: underline;
                  margin-left: 10px;
                  width: 70px;
                }
            }
        }

        >.line {
            &:first-child{
                display: none;
            }
            height: 1px;
            background-color: rgba(98, 97, 97, 0.27);
            margin: 30px 0;
        }
    }
}