:global {
    :local(.wrapper) {
        height: 100%;
        // position: relative;

        .scroll_bar_restyle {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                width: 4px;
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: rgba($color:#C9C9C9, $alpha: 1);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 2px;
            }
        }

        .scroll_bar_restyle_horizon {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                height: 8px;
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: rgba(5, 102, 255, 1);
                border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(225, 230, 240, 1);
                border-radius: 4px;
            }
        }

        >.scroll_wrapper {
            height: 100%;
            overflow: auto;

            >.content {
                // 内部元素超出外部后是不管的
                height: 100%;
                min-height: 650px;
                min-width: 1366px;
                display: flex;

                >.left {
                    background-color: #001529;
                }

                >.right {
                    width: 0;
                    flex-grow: 1;
                    background-color: #F4F5F7;
                    display: flex;
                    flex-direction: column;

                    >.content {
                        height: 0;
                        flex-grow: 1;
                        overflow-y: auto;
                    }
                }
            }
        }

    }
}