:global {
    :local(.wrapper) {
        height: 100%;
        .info_item{
            &.enable_edit:hover{
                cursor: pointer;
                color: #1890ff;
                text-decoration: underline;
            }
            .edit_icon{
                color: #1890ff;
                margin-left: 5px;
                cursor: pointer;
            }
        }

        .btn-line{
            width: 150px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: 265px;
            margin-top: 40px;
        }
    }
}