:global {
    :local(.wrapper) {
        overflow-y: auto;

        .CustomerList {
            font-family: 'Microsoft Yahei';

            .toolBar {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                height: 50px;

                >.leftTools {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                }
            }
            .table_wrapper{
                margin-top: 10px;
            }
        }
    }
}