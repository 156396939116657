:global {
    :local(.wrapper) {
        height: 100%;

        >.content {
            height: 100%;
            overflow-y: auto;
            padding: 40px 40px 30px;
            background-color: rgba(255, 255, 255, 100);

            >.top {
                display: flex;
                align-items: flex-start;

                >.left {
                    width: 280px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    >.upload_wrapper {
                        width: 280px;
                        height: 361px;
                        overflow: hidden;

                        .avatar-uploader>.ant-upload {
                            width: 280px;
                            height: 361px;
                        }

                        img.cover_image{
                                object-fit: cover;
                                height: 100%;
                                width: 100%;
                        }
                    }

                    >.text {
                        margin-top: 20px;
                        color: rgba(98, 97, 97, 100);
                        font-size: 18px;
                        line-height: 27px;
                        text-decoration: underline;
                        cursor: pointer;
                    }

                }

                >.right {
                    width: 0;
                    flex-grow: 1;
                    margin-left: 42px;

                    >.top {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        justify-content: space-between;

                        >.title {
                            color: rgba(16, 16, 16, 100);
                            font-size: 28px;
                            line-height: 40px;
                            font-weight: bold;
                        }
                        >.title_input{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                width: 460px;
                        }
                        >.title_btns{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                width: 300px;
                        }
                    }

                    >.second {
                        margin-top: 15px;
                        display: flex;
                        width: 100%;
                        align-items: center;
                        flex-direction: row;

                        >.title,
                        >.value {
                            font-size: 14px;
                            line-height: 27px;
                            font-weight: bold;
                        }
                        >.title_input{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                width: 100%;
                        }
                        >.title {
                                color: rgba(98, 97, 97, 100);
                        }

                        >.value {
                            color: rgba(16, 16, 16, 100);
                        }

                        >.edit_icon {
                            font-size: 24px;
                            margin-left: 15px;
                            color: rgba(16, 16, 16, 100);
                        }
                    }

                    >.third {
                        margin-top: 5px;
                        font-size: 18px;
                        line-height: 27px;
                        color: rgba(98, 97, 97, 100);
                        font-weight: bold;
                    }

                    .brief_line_display {
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;
                        >.info_name {
                            width: 44px;
                            flex-shrink: 0;
                            flex-grow: 0;
                            font-size: 14px;
                            color: rgba(98, 97, 97, 100);
                            font-weight: bold;
                        }
                        >.info_display {
                            overflow-y: auto;
                            height: 80px;
                            line-height: 26px;
                            font-size: 14px;
                            word-break: break-all;
                            word-wrap: break-word;
                            white-space: pre-wrap;
                            margin-bottom: 0px;
                        }
                    }
                    .brief_line_edit {
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;
                        >.info_name {
                            width: 44px;
                            flex-shrink: 0;
                            flex-grow: 0;
                        }
                        >.info_content {
                            flex-shrink: 1;
                            flex-grow: 1;
                            margin-top: 10px;
                            resize: none;
                            // border: 1px solid rgba(167, 167, 167, 100);
                            border-radius: 4px;
                            font-size: 14px;
                            line-height: 26px;
                            height: 80px;
                            text-align: justify;
                            color: rgba(16, 16, 16, 100);
                            padding: 2px 8px;
                            margin-left: 16px;
                        }
                    }

                    >.textarea_comp {
                        margin-top: 10px;
                        resize: none;
                        // border: 1px solid rgba(167, 167, 167, 100);
                        border-radius: 4px;
                        font-size: 14px;
                        line-height: 26px;
                        height: 216px;
                        text-align: justify;
                        color: rgba(16, 16, 16, 100);
                        padding: 10px 18px;
                    }
                    .detail_info{
                        overflow-y: auto;
                        height: 197px;
                        line-height: 28px;
                        font-size: 14px;
                        margin-top: 6px;
                        word-break: break-all;
                        word-wrap: break-word;
                        white-space: pre-wrap;
                    }
                }

            }

            >.bottom {
                margin-top: 10px;

                .pics {
                    margin-top: 26px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    // justify-content: space-between;
                    position: relative;

                    >.upload_wrapper {
                        // overflow: hidden;

                        &:nth-child(4n+3),
                        &:nth-child(4n+4),
                        &:nth-child(4n+5){
                                margin-left: 1.5%;
                        }
                        &:nth-child(4n+2){
                                margin-left: 0px;
                        }
                        &:nth-child(n+6){
                                margin-top: 24px;
                        }

                        .avatar-uploader {
                            height: 100%;

                            >.ant-upload {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .inner_content {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                }

                .other_images{
                    .other_image {
                        max-height: 200px;
                        max-width: 200px;
                    }
                }
            }
        }
    }
}