:global {
    :local(.wrapper) {
        height: 100%;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
        background-color: rgba(255, 255, 255, 100);
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        .del_mask {
            display: none;
        }
        &:hover .del_mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
            position: absolute;
            top: 0px;
            left: 0px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            color: #DDD;
            font-size: 24px;
            span:hover{
                color: #FFF;
                cursor: pointer;
            }
        }
    }
}