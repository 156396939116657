html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch;
}

#root {
    height: 100%;
}