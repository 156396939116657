:global {
    :local(.inline-menu) {
        width: 80px;
        height: 100%;
        display: flex;
        flex-direction: column;

        &.open-animate {
            width: 200px;
            transition: all 0.5s;

            .menu-switch {
                background-image: url('./res/open_state.png') !important;
                margin-left: 148px;
                transition: all 0.5s;
            }
        }

        &.close-animate {
            width: 80px;
            transition: all 0.5s;

            .menu-switch {
                margin-left: 28px;
                transition: all 0.5s;
            }
        }

        >.bottom {
            height: 0;
            flex-grow: 1;
            overflow-y: auto;
        }

        .active-style {
            color: #357CF7;
            background-color: none;
        }

        .ant-menu-submenu-title {
            height: 50px !important;
            line-height: 50px !important;

            .anticon {
                margin-right: 15px !important;
            }
        }

        .ant-menu-inline-collapsed .ant-menu-submenu-title {
            padding: 0 calc(50% - 24px / 2) !important;
        }

        .ant-menu-submenu-selected {

            &>.ant-menu-submenu-title {
                color: #357CF7;
            }

            .board {
                background-image: url('./res/board_active.png') !important;
            }

            .target {
                background-image: url('./res/target_active.png') !important;
            }

            .form {
                background-image: url('./res/form_active.png') !important;
            }

            .monitor {
                background-image: url('./res/monitor_active.png') !important;
            }

            .cost {
                background-image: url('./res/cost_active.png') !important;
            }

            .events {
                background-image: url('./res/events_active.png') !important;
            }

            .warning {
                background-image: url('./res/warning_active.png') !important;
            }

            .assess {
                background-image: url('./res/assess_active.png') !important;
            }

            .report {
                background-image: url('./res/report_active.png') !important;
            }

            .setting {
                background-image: url('./res/setting_active.png') !important;
            }

            .plus {
                background-image: url('./res/plus_active.png') !important;
            }

            .quality {
                background-image: url('./res/quality_active.png') !important;
            }
            .production {
                background-image: url('./res/production_active.png') !important;
            }
            .operation {
                background-image: url('./res/operation_active.png') !important;
            }
            .gongyi {
                background-image: url('./res/gongyi_active.png') !important;
            }
            .energy {
                background-image: url('./res/energy_active.png') !important;
            }
            .device {
                background-image: url('./res/device_active.png') !important;
            }
        }

        .ant-menu-item-selected {
            color: #357CF7 !important;
            background: none !important;
        }

        .menu-icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            vertical-align: middle;
            background-size: 24px 24px !important;
            background-repeat: no-repeat !important;

            &.menu-switch {
                cursor: pointer;
                margin-top: 20px;
                margin-bottom: 20px;
                background-image: url('./res/close_state.png');
            }

            &.board {
                background: url('./res/board.png');
            }

            &.target {
                background: url('./res/target.png');
            }

            &.form {
                background-image: url('./res/form.png');
            }

            &.monitor {
                background-image: url('./res/monitor.png');
            }

            &.cost {
                background: url('./res/cost.png');
            }

            &.events {
                background: url('./res/events.png');
            }

            &.warning {
                background: url('./res/warning.png');
            }

            &.assess {
                background: url('./res/assess.png');
            }

            &.report {
                background: url('./res/report.png');
            }

            &.setting {
                background: url('./res/setting.png');
            }

            &.plus {
                background: url('./res/plus.png');
            }

            &.quality {
                background-image: url('./res/quality.png');
            }
            &.production {
                background-image: url('./res/production.png');
            }
            &.operation {
                background-image: url('./res/operation.png');
            }
            &.gongyi {
                background-image: url('./res/gongyi.png');
            }
            &.energy {
                background-image: url('./res/energy.png');
            }
            &.device {
                background-image: url('./res/device.png');
            }
        }
    }

    .ant-menu {
        .ant-menu-item-selected {
            color: #357CF7 !important;
            background: none !important;
        }

        .ant-menu-submenu-selected>.ant-menu-submenu-title {
            color: #357CF7;
        }
    }
}